import React from 'react'
import Icon from '../Icons'
import * as s from './style.module.sass'

export default ({ children, type = 'full', icon = null, iconSize = 'normal', width = '', height = '', onClick }) => {
  return (
    <button className={[s.button, s[type]].join(' ')} style={{ width, height }} onClick={onClick || null}>
      <div className={s.content}>
        {icon !== null && (
          <Icon icon={icon} iconSize={iconSize} containerSize={iconSize} className={s.icon}/>
        )}
        <div className={s.contentText}>{children}</div>
      </div>
    </button>
  )
}
