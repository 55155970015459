import React from 'react'
import { Layout } from 'components'
import * as styles from 'styles/404.module.sass'
import { Link } from 'gatsby'
import imageError from 'static/image404.png';
import Button from 'components/Button'
import RightArrow from 'static/right-arrow.svg'

const NotFoundPage = () => (
  <Layout>
    <div className={styles.container}>
      <div className={styles.leftBlock}>
        <article className={styles.content}>
          <h1 className={styles.contentTitle}>Oups !</h1>
          <h2 className={styles.subTitle}>Cette page n'existe pas (erreur 404) !</h2>
          <section className={styles.contentBody}>
            Nous n’avons pas trouvé la page que vous recherchiez.
          </section>
          <div className={styles.links}>
            <Link className={styles.elementHome} to={`${process.env.GATSBY_SITE_URL}`}>
              <RightArrow className={styles.iconArrow} />
              Redirection vers le site Artelink</Link>
            <Link className={styles.elementHome} to={`${process.env.GATSBY_SITE_URL}/tag/freelance`}>
              <RightArrow className={styles.iconArrow} />
              Freelance</Link>
            <Link className={styles.elementHome} to={`${process.env.GATSBY_SITE_URL}/tag/entreprise`}>
              <RightArrow className={styles.iconArrow} />
              Entreprise</Link>
          </div>
        </article>
        <div className={styles.buttonWrapper}>
          <Link className="no-underline" to={`${process.env.GATSBY_SITE_URL}`}>
            <Button type="outlined">
              Retour à l'accueil
            </Button>
          </Link>
        </div>
      </div>
      <div className={styles.rightBlock}>
        <img src={imageError}></img>
      </div>
    </div>
  </Layout>
)

export default NotFoundPage
