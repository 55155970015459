// extracted by mini-css-extract-plugin
export var container = "_404-module--container--GV9LC";
export var leftBlock = "_404-module--leftBlock--RXu_E";
export var rightBlock = "_404-module--rightBlock--11jm0";
export var content = "_404-module--content--1cbIq";
export var contentTitle = "_404-module--contentTitle--2kqVh";
export var subTitle = "_404-module--subTitle--2bgWl";
export var contentBody = "_404-module--contentBody--3wNk-";
export var elementHome = "_404-module--elementHome--2UBA_";
export var links = "_404-module--links--36GyT";
export var iconArrow = "_404-module--iconArrow--2pAN-";
export var element = "_404-module--element--3-pB1";
export var buttonWrapper = "_404-module--buttonWrapper--3Xfte";